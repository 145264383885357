// BEGIN: Utility classes.  Note the use of !important, so can override any built-in styles on the specified elements
// scss-lint:disable ImportantRule

// creating .margin-bottom, .margin-top, .padding-bottom, etc. utility classes
// pattern is: mb-1em = 1em, 5em = .5em, 75em = .75em
// Moving towards a pattern like described here: https://getbootstrap.com/docs/4.0/utilities/spacing/
$margins: (
  0: 0,
  25: .25em,
  5: .5em,
  75: .75em,
  1: 1em,
  15: 1.5em,
  2: 2em,
  3: 3em,
  auto: auto
);
$sides: (
  top: t,
  right: r,
  bottom: b,
  left: l
);

@each $name, $em in $margins {
  @each $side, $abbr in $sides {
    .m#{$abbr}-#{$name} {
      margin-#{$side}: unquote('#{$em}') !important;
    }

    .p#{$abbr}-#{$name} {
      padding-#{$side}: unquote('#{$em}') !important;
    }
  }

  .m-#{$name} {
    margin: unquote('#{$em}') !important;
  }

  .mh-#{$name} {
    margin-right: unquote('#{$em}') !important;
    margin-left: unquote('#{$em}') !important;
  }

  .mv-#{$name} {
    margin-top: unquote('#{$em}') !important;
    margin-bottom: unquote('#{$em}') !important;
  }

  .p-#{$name} {
    padding: unquote('#{$em}') !important;
  }

  .ph-#{$name} {
    padding-right: unquote('#{$em}') !important;
    padding-left: unquote('#{$em}') !important;
  }

  .pv-#{$name} {
    padding-top: unquote('#{$em}') !important;
    padding-bottom: unquote('#{$em}') !important;
  }
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.full-width {
  width: 100%;
}

.no-border {
  border: none !important;
}

.hidden {
  display: none !important;
}

.inline-block {
  display: inline-block;
}

.block {
  display: block;
}

.fixed {
  position: fixed;
}

.normal-weight {
  font-weight: normal;
}
