@import '../../styles/mirror-partials/common';

.radio-input {
  .form-option {
    display: block;
  }

  & > label {
    font-weight: bold;
  }

  input {
    margin-right: $form-input-width;
  }
}
