@import '../partials/colors';

// This file is for Mirror-specific variables that do not exist in partials/_variables

// see also _utilities for loading indicator-related variables

// Colors per new Brand Guide
// primary colors:
$poly-lava: #eb3c00;
$poly-midnight: #00263e;
$white: #fff;
$black: #000;

// secondary colors:
$poly-sand: #eee8d6;
$poly-pearl: #d7d2cb;
$poly-linen: #a59c94;
$poly-slate: #96bb8a;

// tertiary colors:
$poly-stone: #a5cfcb;
$poly-sunflower: #fddd66;
$poly-mint: #99c3a7;
$polycom-blue: $poly-midnight;
$pale-blue: $blue-500; // #5bc0de
$blue: $blue-600; // #31b0d5
$blue-border: $blue-700; // #2aabd2
$link-grey: $bluegrey-700; // #666
$link-blue: $blue-700; // #08c

$body-color: $grey-800;
$body-background: $bluegrey-50;
$lighter-grey: $grey-200;
$light-grey: $grey-400;
$grey: $grey-600;
$dark-grey: $grey-800;
$button-border-radius: 9px;
$button-grey: $grey-200;
$button-border-grey: $grey-300;
$button-hover-border-grey: $grey-400;
$button-active-border-grey: $grey-500;
$button-border-blue: $blue-500;
$button-active: $blue-100;
$button-inactive: $grey-200;
$dark-button-inactive: $grey-600;
$light-blue: lighten($button-border-blue, 10%);
$green: $green-800;
$pale-green: $lightgreen-700;
$light-green: $lightgreen-800;
$light-green-border: $green-500;
$dark-green: $green-900;
$purple: $deeppurple-500;
$red: $red-900;
$orange: $orange-800;

// semantic colors
$success: $green;
$success-light: $green-200;
$success-mid: $green-500;
$success-mid-lighten: $green-400;
$info: $blue-800;
$info-light: $blue-100;
$info-mid: $blue-500;
$info-mid-lighten: $blue-400;
$caution: $amber-600;
$caution-dark: $amber-900;
$caution-light: $amber-100;
$caution-mid: $amber-500;
$caution-mid-lighten: $amber-400;
$warning: $red-600;
$warning-light: $red-200;
$warning-mid: $red-500;
$warning-mid-lighten: $red-400;
$error: $red-600;
$error-light: $red-200;
$error-mid: $red-500;
$error-mid-lighten: $red-400;
$connected: $green;
$disconnected: $red;

// for example, a caret that indicates an increase or decrease over the previous week
$increase: $green-900;
$decrease: $red-900;
$dark: $black;
$light: $white;
$dark-muted: $grey-400;

// Store the heights of these elements so calcs can be made to achieve proper content height
$header-height: 70px;
// Add height (formerly 28px) when footer is added back
$footer-height: 0;
$content-padding-top: 20px;

// nav menu icon variables for simpler tweaking / control
$nav-width: 40px;
$nav-height: 30px;

// see also overlay-transition mixin
$profile-overlay-width: 250px;
$menu-overlay-width: 250px;

// this should change to max($..., $...) if there are multiple overlays on each side
$left-overlay-max-width: $menu-overlay-width;
$right-overlay-max-width: $profile-overlay-width;

// animation related
$intro-delay: 100ms;
$intro-duration: 2s;
// start a little before prior animation finishing
$mirror-delay: -300ms;
$mirror-duration: 1s;
$input-text-indent: 6px;
$card-border-radius: 14px;
$card-content-reveal-duration: 400ms;
$table-border-cell-radius: 5px;

// toastr
$toastr-width: 98%;

// width for radios, checkboxes, switches clickable elements (not labels)
// if < 30px, ensure SwitchInput has enough space to be usable
$form-input-width: 31px;
