@import './variables';
$displays: (
  none: none,
  block: block,
  inline: inline,
  inline-block: inline-block,
  flex: flex
);

// Utilize CSS grid to create a flexible grid layout system.
// This is supported by Modern Browsers, NOT <=IE11.  Instead, see the IE11 fallback in the aptly named iefallback partial.
// see https://caniuse.com/#feat=css-grid
.grid {
  display: grid;
  // "content" - where does the grid go in the space around it?
  // "items" - how do the grid cells lay out in the grid?
  align-content: start;
  align-items: start;
  justify-content: start;

  grid-template-columns: repeat($grid-columns-lg, 1fr);
  justify-items: stretch;

  // Fitted, remove the padding on the end elements to fit the parent.
  &.fitted {
    > :first-child {
      padding-left: 0;
    }

    > :last-child {
      padding-right: 0;
    }
  }

  // Even grid automatically make their columns the same width.
  &.even {
    display: flex;

    .col {
      flex: 1;
    }
  }

  // Stretch the columns to fit the same height.
  &.stretched {
    align-items: stretch;

    grid-auto-rows: 1fr;
    grid-gap: 18px;
  }

  [class*='col-'] {
    box-sizing: border-box;

    &.end {
      text-align: end;
    }

    &.vcenter {
      display: flex;
      align-items: center;
    }

    &.grid {
      padding: 0;
    }
  }

  // "xs" defaults
  // For more info see @help https://getbootstrap.com/docs/4.4/utilities/display/
  @each $d, $dname in $displays {
    .d-#{$d},
    .d-#{$d}-xs {
      display: $d !important;
    }
  }

  // default.  all sizes.
  @for $i from 1 through 12 {
    .col-#{$i} {
      grid-column: span $i;

      @for $o from 1 through (12 - $i) {
        @if $o < 12 {
          &.offset-#{$o} {
            grid-column: ($o + 1) / span $i;
          }
        }
      }
    }
  }

  @for $i from 1 through 4 {
    .row-#{$i} {
      grid-row: span $i;
    }
  }

  @each $name, $size in $screen-sizes {
    @media only screen and (min-width: $size) {
      @for $i from 1 through 12 {
        .col-#{$name}-#{$i} {
          grid-column: span $i;

          @for $o from 1 through (12 - $i) {
            @if $o < 12 {
              &.offset-#{$name}-#{$o} {
                grid-column: ($o + 1) / span $i;
              }
            }
          }
        }
      }

      @each $d, $dname in $displays {
        .d-#{$d}-#{$name} {
          display: $d !important;
        }
      }

      @for $i from 1 through 4 {
        .row-#{$name}-#{$i} {
          grid-row: span $i;
        }
      }
    }
  }
}
