@import './common';

/**
 * CSS related to user interactions common between components.
 */
@mixin disabled {
  color: $grey-300;
  cursor: no-drop;
}

.actions {
  position: relative;

  .action {
    display: inline-block;
    position: relative;
    width: 24px;
    height: 20px;
    border: 1px solid rgba($button-border-grey, .6);
    border-radius: 3px;
    background: linear-gradient(180deg, rgba($white, 1) 0%, rgba($grey-300, 1) 100%);
    text-align: center;

    &:disabled {
      @include disabled;
    }
  }

  button.action:not(.button) {
    height: 22px;
    padding: 2px 2px 4px 2px;
  }

  .action.warning {
    border-color: rgba($red-100, .3);
    background: linear-gradient(180deg, rgba($red-100, 1) 0%, rgba($red-200, 1) 100%);
  }

  .action.warning:hover {
    border-color: rgba($red-200, .6);
  }

  .action:hover {
    border-color: rgba($button-hover-border-grey, .8);
  }

  .action:active {
    top: 1px;
    border-color: $button-active-border-grey;
  }

  .action + .action {
    margin-left: 4px;
  }
}

.left-col-image {
  img {
    display: block;
    max-width: 100%;
    margin: 0 auto;
  }
}

.table {
  width: 100%;
  border-collapse: collapse;

  // only hide .showOnHover results in large table formats
  @media screen and (min-width: $table-responsive-max-width) {
    tr:not(.inline-add) td.showOnHover {
      opacity: 0;
    }
  }

  thead tr {
    background-color: $cyan-700;
    color: $white;

    th {
      padding: .1em 1em;
      text-align: left;

      .poly-icon {
        fill: $white !important;
      }
    }
  }

  tbody {
    tr td {
      padding: .35em 1em;
    }

    tr:nth-child(even) {
      background-color: $grey-100;
    }

    tr.inline-add {
      background-color: mix($white, $green-50);

      &:hover {
        background-color: $green-200;
      }
    }

    tr.inline-add {
      td {
        padding-top: .75em;
        vertical-align: top;
      }

      input.form-input,
      select.form-input {
        padding-left: .5rem;
      }

      select.form-input.form-control,
      .form-input .rw-widget-picker {
        height: 2rem;
      }

      select.form-input.form-control {
        padding-top: .250rem;
      }
    }

    tr:hover {
      background-color: $cyan-100;

      &:not(.inline-add) td.showOnHover {
        // included in case .tooltip is in this td element
        z-index: 1000;
        animation: fadeIn $card-content-reveal-duration forwards;
      }
    }
  }

  &.rounded {
    border-radius: $table-border-cell-radius + 2px;

    th:first-child {
      border-radius: $table-border-cell-radius 0 0;
    }

    th:last-child {
      border-radius: 0 $table-border-cell-radius 0 0;
    }

    th:only-child {
      border-radius: $table-border-cell-radius $table-border-cell-radius 0 0;
    }

    // last row
    tr:last-child {
      td:first-child {
        border-radius: 0 0 0 $table-border-cell-radius;
      }

      td:last-child {
        border-radius: 0 0 $table-border-cell-radius;
      }
    }
  }
}

/* support tiny tables on small devices such as smartphones */
@media screen and (max-width: $table-responsive-max-width) {
  .table {
    & > thead {
      position: absolute;
      width: 1px;
      height: 1px;
      margin: -1px;
      padding: 0;
      overflow: hidden;
      clip: rect(0 0 0 0);
      border: none;
    }

    /* note must be direct descendant, otherwise impacts sub-tables such as DateTimePicker */
    & > tbody > tr {
      display: block;
      position: relative;
      margin-bottom: .625em;
      border-bottom: 3px solid $grey-300;

      & > td {
        display: block;
        width: auto;
        /* prevents 'collapse' if no td content */
        min-height: 1.4em;
        padding-left: $table-responsive-split + $table-responsive-split-space !important;
        border-bottom: 1px solid $grey-300;
        font-size: 90%;
      }

      /* hide rows (converted from larger table layout columns) with empty data */
      & > td:empty {
        display: none;
      }

      & > td::before {
        content: attr(data-label);
        position: absolute;
        left: 1em;
        width: $table-responsive-split;
        font-weight: bold;
      }

      & > td:last-child {
        border-bottom: 0;
      }
    }
  }
}

.left-col-sticky {
  position: sticky;
  top: 11em;;

  // special class to animate left column from a right position
  &.animate-from-right {
    // note this overrides default card animation
    animation: animate-from-right 200ms linear forwards;
  }
}

@keyframes animate-from-right {
  0% {
    transform: translateX(100%);
    opacity: .5;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.selected {
  border: 1px solid #c4811d;
}

/* for additional styles for cards selected / not selected, see git@onecode.polycom-labs.com:rmckeel/store-promo.git */
.product .wideImage,
.provider .wideImage {
  width: 95px;
}

.button {
  display: inline-block;
  margin-top: .75em;
  padding: 7px 20px;
  border: 1px solid $button-border-grey;
  border-radius: 9px;
  background-color: $white;
  color: $black;
  font-size: 22px;
  letter-spacing: .5px;
  text-decoration: none;
  cursor: pointer;

  &.button-sm {
    padding: 4px 14px;
    font-size: 16px;
  }

  &.button-xs {
    padding: 2px 10px;
    font-size: 12px;
  }

  /* utility for icon-only buttons */
  &.button-icon-only {
    padding: 2px 5px;
    border: 0;
    font-size: 12px;
  }

  &:hover {
    border-color: $button-hover-border-grey;
  }

  &:active,
  &.active {
    border-color: $button-active-border-grey;
  }

  &.wide {
    width: 100%;
    border-radius: 0;
  }

  &.left-col {
    width: 80%;
    border: none;

    &:hover,
    &.active {
      border: none;
      background-color: rgba($grey-500, .5);
    }
  }

  &.right {
    position: absolute;
    right: 0;
  }

  &:disabled {
    @include disabled;
  }

  &:not(.button-icon-only) .poly-icon {
    margin-right: .25em;
    margin-left: -.25em;
  }
}

a.button,
.button.button-md {
  font-size: 18px;
}

.button.button-lg {
  font-size: 30px;
}

.button-group {
  .button {
    border-radius: 0;

    /* reset from default .button .poly-icon margins */
    .poly-icon {
      margin: 0;
    }
  }

  .button:first-child {
    border-top-left-radius: $button-border-radius;
    border-bottom-left-radius: $button-border-radius;
  }

  .button:last-child {
    border-top-right-radius: $button-border-radius;
    border-bottom-right-radius: $button-border-radius;
  }

  .button:not(:last-child) {
    border-right: none;
  }

  .button:not(.active) .poly-icon {
    fill: $dark-muted;
  }
}

.success {
  border: 1px solid $success-mid;
  background: $success-light;

  &:active {
    background: $success-mid-lighten;
  }
}

.success-highlight {
  color: $success;

  .poly-icon {
    fill: $success !important;
  }
}

.info {
  border: 1px solid $info-mid;
  background: $info-light;

  &:active {
    background: $info-mid-lighten;
  }
}

.info-highlight {
  color: $info;

  .poly-icon {
    fill: $info !important;
  }
}

.caution {
  border: 1px solid $caution-mid-lighten;
  background: $caution;

  &:active {
    background: $caution-mid;
  }
}

.caution-highlight {
  color: $caution-dark;

  .poly-icon {
    fill: $caution-dark !important;
  }
}

.warning:not(.poly-icon) {
  border: 1px solid $warning-light;
  background: $warning-mid;
  color: $white;

  a {
    color: $white;
  }

  .poly-icon {
    fill: $white;
  }

  &:active {
    background: $warning-mid-lighten;
  }
}

.warning-highlight {
  color: $warning;

  .poly-icon {
    fill: $warning !important;
  }
}

p.info,
p.caution,
p.error,
p.success,
p.warning,
div.info,
div.caution,
div.error,
div.success,
div.warning,
pre.warning {
  padding: .5em 1em;
  font-size: 18px;
}

p.notice-sm,
div.notice-sm,
pre.notice-sm {
  padding: .25em .5em;
  font-size: 14px;
}

// for use on light or white backgrounds
.app-root.dark {
  // dark-colored overlays
  .overlay {
    @include blur(.5);

    color: $dark;

    &.from-left {
      border-right: 1px solid $grey-300;
    }

    &.from-right {
      border-left: 1px solid $grey-300;
    }
  }
}

// for use on darker backgrounds
.app-root.light {
  // light-colored overlays
  .overlay {
    @include black-blur(.75);

    color: $light;

    .poly-icon {
      fill: $light;
    }
  }
}

// overlays, for example profile or menu
.overlay {
  @include overlay-transition();

  position: absolute;
  top: 0;
  height: 100vh;
  opacity: 0;
  text-align: center;

  filter: blur(20px);

  &.show {
    opacity: 1;

    filter: blur(0);
  }

  &.from-right {
    right: 0;
    transform: translateX($right-overlay-max-width) scaleX(1) scaleY(1);
  }

  &.from-right.show {
    transform: translateX(0) scaleX(1) scaleY(1);
  }

  &.from-left {
    left: 0;
    transform: translateX(-1 * $left-overlay-max-width) scaleX(1) scaleY(1);
  }

  &.from-left.show {
    transform: translateX(0) scaleX(1) scaleY(1);
  }
}

.overlay .buttons {
  position: absolute;
  bottom: 0;
  width: 100%;

  .button {
    margin-top: 1px;
  }
}

.poly-icon {
  vertical-align: -.125em;
}

/* provide utility classes for other common alignment requirements */
.poly-icon.align-35 {
  vertical-align: -.35em;
}

/* animation speeds currently supported are:
 1X (every 100ms)
 2X (every 50ms)
 */
@for $o from 1 through 60 {
  .animation-delay-#{$o * 50} {
    animation-delay: #{$o * 50}ms;
  }
}

.redux-toastr {
  .toastr {
    min-height: 40px;

    .rrt-left-container .rrt-holder {
      line-height: 75px;
    }
  }

  .top-center {
    top: $header-height;
  }
}

// simple utility class
.pointer {
  cursor: pointer;
}
