@import '../../styles/mirror-partials/common';

nav {
  ul {
    text-align: center;
  }

  li {
    display: inline-block;
    padding: 8px 20px;
    font-weight: 700;
    line-height: 22px;
    vertical-align: top;
  }

  a {
    display: block;
    position: relative;
    color: $black;
    text-decoration: none;

    &:after {
      content: '';
      position: absolute;
      right: 50%;
      bottom: -8px;
      left: 50%;
      transition: left .2s ease-in-out, right .2s ease-in-out;
      border-bottom: 1px solid $black;
    }

    &.active:after {
      right: 0;
      left: 0;
    }
  }
}

/* Nav in overlay, in case used again
nav {
  $nav-background: $grey-100;
  position: relative;
  height: 100%;
  min-height: 100vh;
  color: $black;
  white-space: nowrap;

  a {
    display: inline-block;
    text-decoration: none;
  }
}
 */

#menuOverlay,
#profileOverlay,
header {
  // overlays need to be on top of regular content
  z-index: 10;
}

#menuOverlay,
#profileOverlay {
  // overlay should stay on screen even if user is scrolling content
  position: fixed;
}

#menuOverlay {
  width: $menu-overlay-width;
}

#profileOverlay {
  z-index: 10;
  width: $profile-overlay-width;

  .profileData {
    font-weight: bold;
  }
}

.menuContainer {
  position: relative;
  width: 100%;

  ul {
    $offset: 28px;
    display: block;
    width: 100%;
    margin-top: 3px;
    padding: 0;
    list-style: none;
    text-align: left;

    a {
      width: calc(100% - #{$offset});
      padding: 13px 0 14px $offset;
      font-weight: 400;
    }

    & > li {
      width: 100%;
      height: 52px;
      font-size: 16px;
    }

    a.active,
    a:focus {
      font-weight: bold;
    }

    // sub-nav
    ul {
      $sub-nav-padding-left: 40px;
      $sub-nav-focused-border: 6px;
      margin: 0;
      padding: 0;
      font-size: 14px;
      list-style: none;

      li {
        width: calc(100% + 1em + 2px);
        height: 48px;
      }

      li a {
        padding-left: $sub-nav-padding-left;
      }

      li a:focus {
        padding-left: $sub-nav-padding-left - $sub-nav-focused-border;
        border-left: $sub-nav-focused-border solid $blue-600;
      }
    }

    .poly-icon {
      vertical-align: -.35em;
    }
  }
}

.app-root.light #menuOverlay {
  ul > li {
    border-bottom: 1px solid $grey-800;
    background-color: mix($grey-800, $grey-900, 40%);

    a {
      color: $light;
    }

    &:hover {
      background-color: $grey-300;
    }
  }
}

.app-root.dark #menuOverlay,
.app-root.dark #profileOverlay {
  background-color: $grey-200;
}

.app-root.dark #menuOverlay {
  ul > li {
    border-bottom: 1px solid $grey-800;
    background-color: $grey-100;

    a {
      color: $dark;
    }

    &:hover {
      background-color: $grey-300;
    }
  }
}

header {
  width: 100%;
  height: $header-height;
  padding: 0 20px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 80%, rgba(255, 255, 255, 0) 100%);

  &,
  .help a {
    color: $white;
  }

  .notifications,
  .help {
    display: inline-block;
    padding: 6px 5px 0;
  }

  .notifications {
    h3 {
      margin: 5px 0 0 11px;
      padding: 0;
      overflow: hidden;
      color: mix($grey-500, $poly-midnight);
      font-size: 18px;
    }
  }

  .notifications {
    position: relative;
    margin-right: 5px;
    padding-bottom: 26px;

    .count {
      display: inline-block;
      min-width: 20px;
      height: 20px;
      margin-left: -8px;
      border-radius: 50%;
      background: $red-900;
      color: $white;
      font-size: 13px;
      line-height: 20px;
      text-align: center;
    }
  }

  .help {
    margin-right: 14px;

    a {
      text-decoration: none;
    }
  }

  .container {
    display: flex;
    align-items: center;
  }

  .logo {
    .polymark.busy {
      animation: spin 1.3s linear infinite;
    }
  }

  // notifications, work in progress
  /*
  li.info {
    @include notification-item($blue-600, mix($blue-50, $white), $blue-100, $fa-var-info-circle);
  }

  li.error {
    @include notification-item($red-600, mix($red-50, $white), $red-100, $fa-var-exclamation-circle);
  }

  li.success {
    @include notification-item($green-600, mix($green-50, $white), $green-100, $fa-var-check);
  }
   */
}

.app-root.dark header .poly-icon:not(.muted),
.app-root.dark header .logo img {
  fill: $dark;
}

// muted variant of dark is a medium grey
.app-root.dark header .poly-icon.muted,
.app-root.dark header .logo img {
  fill: $dark-muted;
}

.app-root.light header .poly-icon,
.app-root.light header .logo img {
  fill: $light;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-359deg);
  }
}
