.login-splash {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
  background-position: center center;
  background-size: cover;
}

.logo-wrap {
  display: flex;
  align-content: center;
  align-items: center;
  margin: 10% auto 16px;
  animation: move-up-and-scale-down 1.5s ease-in-out;

  .poly {
    height: 80px;
    animation: poly 1.3s ease-in-out .1s;
  }

  .mark {
    height: 60px;
    margin-left: 20px;
  }
}

.login-panel {
  display: flex;
  top: 100px;
  box-sizing: border-box;
  flex-direction: column;
  width: 22em;
  margin: 0 auto;
  animation: fadeIn 2s ease-out;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, .85);
  box-shadow: 1px 1px 1px 0 #000;

  &.extend_border {
    min-height: 50%;
  }

  h2 {
    color: #fff;
    text-align: center;
  }
}
