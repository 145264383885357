@import '../../styles/mirror-partials/common';

// TODO would be nice to have a CSS transition here,
//  however animating grid-template-columns seems largely unsupported by browsers as of June 2019
.app-root {
  @include overlay-transition('margin-right, margin-left');

  position: relative;
  height: 100vh;
  background-size: cover;

  grid-row-gap: 5px;
  grid-template-rows: 48px auto 28px;

  &.signedIn {
    grid-template-rows: $header-height auto $footer-height;

    main {
      height: calc(100vh - #{$footer-height} - #{$header-height});
      margin-top: $header-height;
    }
  }

  &.signedOut {
    grid-template-rows: auto;
    justify-items: center;

    main {
      display: grid;
      align-items: center;
      height: 100vh;

      justify-items: center;
    }
  }

  &.collapsed {
    grid-template-columns: 40px 40px repeat(10, 1fr);
  }

  &.profile-overlay {
    padding-right: $profile-overlay-width;
  }

  &.menu-overlay {
    padding-left: $menu-overlay-width;
  }

  &.dark {
    .poly-icon {
      fill: $black;
    }

    .status {
      font-size: 80%;
      text-transform: uppercase;

      span {
        display: inline-block;

        &::first-letter {
          font-size: 140%;
        }
      }
    }

    .connected {
      fill: $green;
      color: $green;
    }

    .connected .poly-icon {
      fill: $green;
    }

    .disconnected {
      fill: $red;
      color: $red;
    }

    .disconnected .poly-icon {
      fill: $red;
    }

    .muted {
      fill: $dark-muted;
      color: $dark-muted;
    }
  }

  &.light {
    .poly-icon {
      fill: $white;
    }
  }
}

footer {
  height: $footer-height;
  opacity: .8;
  letter-spacing: .5px;

  a {
    color: $link-blue;
    text-decoration: none;
  }
}
