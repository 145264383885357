@import '../../styles/mirror-partials/common';

.page-header {
  h1 {
    text-align: center;
  }

  .title {
    align-items: center;
    justify-content: center;
    width: 100%;
    color: $grey-800;
    font-size: calc(10px + 1.5vh);

    .title-main {
      animation: fadeInRight .4s ease-in;
      font-size: 1.75em;
    }

    .subtitle {
      font-size: .85em;
      font-weight: normal;
    }
  }
}
