// Media Query Sizes
// Grid is built similarly to Bootstrap 4.x, see:
// @help https://getbootstrap.com/docs/4.0/layout/grid/
$screen-sizes: (
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px
);
$table-responsive-max-width: map-get($screen-sizes, 'lg');
$table-responsive-split: 19%;
$table-responsive-split-space: 4%;

// Grid
$grid-columns-lg: 12;

// Typography
$font-family: Lato, sans-serif;
$base-font-size: 100%;
// NOTE: This is unitless intentionally.
// @help https://css-tricks.com/almanac/properties/l/line-height/#article-header-id-0
$base-line-height: 1.4;

// alerts
$success-background: $green-50;
$success-border: $green-200;
$success-color: $green-800;
$error-background: $red-50;
$error-border: $red-100;
$error-color: $red-800;
$info-background: $blue-50;
$info-border: $blue-100;
$info-color: $blue-800;
$warning-background: $amber-50;
$warning-border: $amber-200;
$warning-color: $brown-900;
$input-border-grey: $grey-500;

// buttons
$button-border-radius: 4px;
$button-default-background: $grey-100;
$button-default-border: $grey-300;
$button-default-color: $grey-800;
$button-default-hover-background: $grey-200;
$button-default-hover-border: $grey-400;
$button-default-hover-color: $grey-900;
$button-primary-background: $blue-600;
$button-primary-border: $blue-800;
$button-primary-color: $white;
$button-primary-hover-background: $blue-700;
$button-primary-hover-border: $blue-900;
$button-primary-hover-color: $white;
$button-danger-background: $red-600;
$button-danger-border: $red-800;
$button-danger-color: $white;
$button-danger-hover-background: $red-700;
$button-danger-hover-border: $red-900;
$button-danger-hover-color: $white;
