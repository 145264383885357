@import '../styles/mirror-partials/common';

// #root included for higher specificity
#root .toastr {
  .poly-icon {
    fill: $white !important;
  }

  &.rrt-success {
    background-color: $success;
  }

  &.rrt-info {
    background-color: $info;
  }

  &.rrt-warning {
    background-color: $caution-dark;
  }

  &.rrt-error {
    background-color: $error;
  }

  .close-toastr {
    color: $white;
  }
}
