@import './colors';

// "loading indicators" - "abstract" placeholders of the data that should be rendered

// Styles for the loading indicator "placeholders" on the dashboard
// pulse "dark" color
$mask-dark: $grey-300; // #dddddd
// pulse "light" color
$mask-light: $grey-200; //  #e6e6e6
// mask width. Must be 2x element width
$mask-width: 500px;
// calculate starting / ending positions of animation
$mask-left-start: ($mask-width / -2);
$mask-right-end: ($mask-width / 2);

// full width variants
$mask-full-width: 1500px;
// calculate starting / ending positions of animation
$mask-full-left-start: ($mask-full-width / -2);
$mask-full-right-end: ($mask-full-width / 2);

// how long (in seconds) the pulse will take to cross the mask
$pulse-time: .5;
// how long (in seconds) between "pulses"
$pulse-interval: 5;
// how long (in seconds) before first pulse runs
$pulse-delay: 2s;
// calc proper values, cast as desired units
$pulse-percent: $pulse-time / $pulse-interval * 100%;
$pulse-interval: $pulse-interval * 1s;

.abstract {
  max-width: 100%;
  height: 15px;
  margin: 3px 3px 0;
  animation: $pulse-interval pulseBG $pulse-delay linear infinite;
  border-radius: 6px;
  background: linear-gradient(
  to right,
  $mask-dark 0%,
  $mask-light 5%,
  $mask-light 15%,
  $mask-dark 20%,
  $mask-dark 100%
  ) $mask-left-start 0;
  background-size: $mask-width 30px;
}

.abstract-inline-block {
  display: inline-block;
}

.abstract-block {
  display: block;
}

.abstract-spacer {
  background: none;
}

.abstract-on-dark {
  opacity: .25;
}

.abstract-xs {
  width: 50px;
}

.abstract-sm,
%abstract-sm {
  width: 100px;
}

.abstract-md {
  width: 150px;
}

.abstract-lg {
  width: 200px;
}

.abstract-full {
  width: 100%;
  /* special override for potentially large abstract placeholders */
  animation: $pulse-interval pulseBGFull $pulse-delay linear infinite;
  background-size: $mask-full-width 30px;
}

.abstract-no-animation {
  animation: none;
}

.abstract-message {
  width: 400px;
  margin: 18px 20px;
}

.abstract-sm-square {
  height: 100px;

  @extend %abstract-sm;
}

.abstract-sm-square-center {
  height: 100px;
  margin: 0 auto;

  @extend %abstract-sm;
}

.abstract-large-number {
  width: 90px;
  height: 45px;
}

@keyframes pulseBG {
  0% {
    background-position: $mask-left-start 0;
  }

  #{$pulse-percent} {
    background-position: $mask-right-end 0;
  }

  100% {
    background-position: $mask-right-end 0;
  }
}

@keyframes pulseBGFull {
  0% {
    background-position: $mask-full-left-start 0;
  }

  #{$pulse-percent} {
    background-position: $mask-full-right-end 0;
  }

  100% {
    background-position: $mask-full-right-end 0;
  }
}
