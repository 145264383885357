@import '../../styles/mirror-partials/common';

.switch-input {
  .form-option {
    display: block;

    label {
      &::after {
        content: attr(data-text);
        position: relative;
        top: -3px;
        margin-left: calc(#{$form-input-width} + 14px);
      }
    }
  }

  & > label {
    font-weight: bold;
  }
}
