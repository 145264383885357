@mixin blur($percent: .85) {
  background: rgba(255, 255, 255, $percent);
  color: black !important;

  backdrop-filter: blur(5px);
}

@mixin black-blur($percent: .85) {
  background: rgba(0, 0, 0, $percent);

  backdrop-filter: blur(5px);
}


// fontawesome variants used, full list is .far (regular), .fal (light), .fas (solid), .fab (brands)
// both solid and "regular" (open) in use
@mixin fa {
  .fa,
  .far,
  .fas {
    @content;
  }
}

@mixin notification-item($border-color, $background-color, $hover-color, $icon) {
  position: relative;
  border-left: 1px solid $border-color;
  background-color: $background-color;

  span {
    display: block;
    padding-left: 20px;
  }

  i {
    position: absolute;
    bottom: .25em;
    left: .3em;
    width: 1.25em;
    text-align: center;

    &::before {
      content: fa-content($icon);
      color: $border-color;

      @extend %fa-custom;
    }
  }

  &:hover {
    background-color: $hover-color;
  }
}

@mixin overlay-transition($properties: all) {
  transition-property: $properties;
  transition-duration: 800ms;
  transition-timing-function: cubic-bezier(.230, 1, .320, 1);
}
