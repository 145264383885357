body {
  font-family: $font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin: 0;
  padding: 0;
  font-family: $font-family;
  font-weight: bold;
  line-height: 1.2;
}

h1,
.h1 {
  font-size: 2rem;
}

h2,
.h2 {
  font-size: 1.70rem;
}

h3,
.h3 {
  font-size: 1.6rem;
}

h4,
.h4 {
  font-size: 1.5rem;
}

h5,
.h5 {
  font-size: 1.4rem;
}

h6,
.h6 {
  font-size: 1.2rem;
}

.text-xs {
  font-size: .7rem !important;
}

.text-sm {
  font-size: .8rem !important;
}

.text-md {
  font-size: .9rem !important;
}

.text-lg {
  font-size: 1.1rem !important;
}
