@import '../../styles/mirror-partials/common';

.search-placeholder-wrapper {
  position: relative;
  min-height: calc(70vh - #{$header-height});
}

.search-placeholder-wrapper,
.pagination-controls {
  // avoid flash of content in the midst of searching as state is changing rapidly
  animation: fadeIn 700ms forwards;
}

.search-placeholder {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  img {
    max-width: 300px;
    opacity: .15;
  }

  p {
    margin-top: .5em;
    font-size: 180%;
  }
}

.devices-landing-page {
  margin: 0 auto;
  padding: .5em 3vw;
  text-align: center;
}

.devices-results-page .page-container {
  min-height: calc(90vh - #{$header-height});
}

.new-devices {
  margin-top: 1.25em;
  font-size: 25px;
  text-align: center;
}

.device-register .plus-1,
.device-register .plus-2 {
  position: absolute;
  z-index: -1;
}

.device-register .plus-1 {
  right: 2em;
  bottom: 2em;
  transform: scale(1.5);
  fill: $grey-300 !important;
}

.device-register .plus-2 {
  top: 4em;
  left: 4em;
  transform: scale(4);
  fill: $grey-100 !important;
}

.card.device {
  margin: 1em 2em 2em 0;

  .content {
    height: 100px;
    margin-top: -21px;
  }

  > label {
    font-size: 16px;

    svg {
      width: 20px !important;
      height: 20px !important;
    }

    span {
      opacity: 0;

      &::first-letter {
        font-size: 22px;
      }
    }
  }

  &:hover {
    label span {
      animation: fadeIn $card-content-reveal-duration forwards;
    }
  }
}
