@import '../../styles/mirror-partials/common';

.checkbox-input {
  .form-option {
    display: block;

    .checkbox-wrapper,
    > label {
      display: inline-block;
      vertical-align: middle;
    }
  }

  > label {
    font-weight: bold;
  }

  input {
    margin-right: $form-input-width;
  }
}
