@import '../../styles/mirror-partials/common';

.content {
  &.decrease .change {
    margin-top: -22px;
  }

  &.increase .change {
    margin-top: 30px;
  }

  &.increase .changePercent {
    color: $increase;

    .poly-icon {
      fill: $increase;
    }
  }

  &.increase .changeLabel {
    color: mix($black, $increase, 90%);
  }

  &.decrease .changePercent {
    color: $decrease;

    .poly-icon {
      fill: $decrease;
    }
  }

  &.decrease .changeLabel {
    color: mix($black, $decrease, 90%);
  }
}

.landing-page {
  margin: 0 auto;
  padding: .5em 3vw;
}

@keyframes fadeInRight {
  0% {
    transform: translateX(50%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
