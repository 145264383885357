@import '../../styles/mirror-partials/common';

.tooltip-container {
  $tooltip-top: 24px;
  $tooltip-background: $grey-300;
  $tooltip-chevron-width: 6px;
  $tooltip-border-width: 1px;
  $tooltip-border: $grey-400;
  display: inline-block;
  position: relative;
  cursor: default;

  .tooltip {
    display: none;
    position: absolute;
    z-index: 10000;
    top: 24px;
    left: 50%;
    padding: 1px 5px;
    transform: translateX(-50%);
    border: $tooltip-border-width solid $tooltip-border;
    border-radius: 5px;
    background: $tooltip-background;
    color: $grey-900;
    text-align: center;

    &::after,
    &::before {
      content: ' ';
      position: absolute;
      top: 0;
      left: 50%;
      width: 0;
      height: 0;
      transform: translateY(-100%);
      border: solid transparent;
      pointer-events: none;
    }

    &::after {
      margin-left: -$tooltip-chevron-width + ($tooltip-border-width * 2);
      border-width: $tooltip-chevron-width - ($tooltip-border-width * 2);
      border-color: transparent transparent $tooltip-background;
    }

    &::before {
      margin-left: -$tooltip-chevron-width;
      border-width: $tooltip-chevron-width;
      border-color: transparent transparent $tooltip-border;
    }
  }

  .action + .tooltip {
    top: $tooltip-top + 4px;
  }

  &:hover .tooltip {
    display: inline-block;
  }
}
