// taken from https://www.google.com/design/spec/style/color.html#color-color-palette
$red-50: #ffebee;
$red-100: #ffcdd2;
$red-200: #ef9a9a;
$red-300: #e57373;
$red-400: #ef5350;
$red-500: #f44336;
$red-600: #e53935;
$red-700: #d32f2f;
$red-800: #c62828;
$red-900: #b71c1c;
$red-a100: #ff8a80;
$red-a200: #ff5252;
$red-a400: #ff1744;
$red-a700: #d50000;
$pink-50: #fce4ec;
$pink-100: #f8bbd0;
$pink-200: #f48fb1;
$pink-300: #f06292;
$pink-400: #ec407a;
$pink-500: #e91e63;
$pink-600: #d81b60;
$pink-700: #c2185b;
$pink-800: #ad1457;
$pink-900: #880e4f;
$pink-a100: #ff80ab;
$pink-a200: #ff4081;
$pink-a400: #f50057;
$pink-a700: #c51162;
$purple-50: #f3e5f5;
$purple-100: #e1bee7;
$purple-200: #ce93d8;
$purple-300: #ba68c8;
$purple-400: #ab47bc;
$purple-500: #9c27b0;
$purple-600: #8e24aa;
$purple-700: #7b1fa2;
$purple-800: #6a1b9a;
$purple-900: #4a148c;
$purple-a100: #ea80fc;
$purple-a200: #e040fb;
$purple-a400: #d500f9;
$purple-a700: #a0f;
$deeppurple-50: #ede7f6;
$deeppurple-100: #d1c4e9;
$deeppurple-200: #b39ddb;
$deeppurple-300: #9575cd;
$deeppurple-400: #7e57c2;
$deeppurple-500: #673ab7;
$deeppurple-600: #5e35b1;
$deeppurple-700: #512da8;
$deeppurple-800: #4527a0;
$deeppurple-900: #311b92;
$deeppurple-a100: #b388ff;
$deeppurple-a200: #7c4dff;
$deeppurple-a400: #651fff;
$deeppurple-a700: #6200ea;
$indigo-50: #e8eaf6;
$indigo-100: #c5cae9;
$indigo-200: #9fa8da;
$indigo-300: #7986cb;
$indigo-400: #5c6bc0;
$indigo-500: #3f51b5;
$indigo-600: #3949ab;
$indigo-700: #303f9f;
$indigo-800: #283593;
$indigo-900: #1a237e;
$indigo-a100: #8c9eff;
$indigo-a200: #536dfe;
$indigo-a400: #3d5afe;
$indigo-a700: #304ffe;
$blue-50: #e3f2fd;
$blue-100: #bbdefb;
$blue-200: #90caf9;
$blue-300: #64b5f6;
$blue-400: #42a5f5;
$blue-500: #2196f3;
$blue-600: #1e88e5;
$blue-700: #1976d2;
$blue-800: #1565c0;
$blue-900: #0d47a1;
$blue-a100: #82b1ff;
$blue-a200: #448aff;
$blue-a400: #2979ff;
$blue-a700: #2962ff;
$lightblue-50: #e1f5fe;
$lightblue-100: #b3e5fc;
$lightblue-200: #81d4fa;
$lightblue-300: #4fc3f7;
$lightblue-400: #29b6f6;
$lightblue-500: #03a9f4;
$lightblue-600: #039be5;
$lightblue-700: #0288d1;
$lightblue-800: #0277bd;
$lightblue-900: #01579b;
$lightblue-a100: #80d8ff;
$lightblue-a200: #40c4ff;
$lightblue-a400: #00b0ff;
$lightblue-a700: #0091ea;
$cyan-50: #e0f7fa;
$cyan-100: #b2ebf2;
$cyan-200: #80deea;
$cyan-300: #4dd0e1;
$cyan-400: #26c6da;
$cyan-500: #00bcd4;
$cyan-600: #00acc1;
$cyan-700: #0097a7;
$cyan-800: #00838f;
$cyan-900: #006064;
$cyan-a100: #84ffff;
$cyan-a200: #18ffff;
$cyan-a400: #00e5ff;
$cyan-a700: #00b8d4;
$teal-50: #e0f2f1;
$teal-100: #b2dfdb;
$teal-200: #80cbc4;
$teal-300: #4db6ac;
$teal-400: #26a69a;
$teal-500: #009688;
$teal-600: #00897b;
$teal-700: #00796b;
$teal-800: #00695c;
$teal-900: #004d40;
$teal-a100: #a7ffeb;
$teal-a200: #64ffda;
$teal-a400: #1de9b6;
$teal-a700: #00bfa5;
$green-50: #e8f5e9;
$green-100: #c8e6c9;
$green-200: #a5d6a7;
$green-300: #81c784;
$green-400: #66bb6a;
$green-500: #4caf50;
$green-600: #43a047;
$green-700: #388e3c;
$green-800: #2e7d32;
$green-900: #1b5e20;
$green-a100: #b9f6ca;
$green-a200: #69f0ae;
$green-a400: #00e676;
$green-a700: #00c853;
$lightgreen-50: #f1f8e9;
$lightgreen-100: #dcedc8;
$lightgreen-200: #c5e1a5;
$lightgreen-300: #aed581;
$lightgreen-400: #9ccc65;
$lightgreen-500: #8bc34a;
$lightgreen-600: #7cb342;
$lightgreen-700: #689f38;
$lightgreen-800: #558b2f;
$lightgreen-900: #33691e;
$lightgreen-a100: #ccff90;
$lightgreen-a200: #b2ff59;
$lightgreen-a400: #76ff03;
$lightgreen-a700: #64dd17;
$lime-50: #f9fbe7;
$lime-100: #f0f4c3;
$lime-200: #e6ee9c;
$lime-300: #dce775;
$lime-400: #d4e157;
$lime-500: #cddc39;
$lime-600: #c0ca33;
$lime-700: #afb42b;
$lime-800: #9e9d24;
$lime-900: #827717;
$lime-a100: #f4ff81;
$lime-a200: #eeff41;
$lime-a400: #c6ff00;
$lime-a700: #aeea00;
$yellow-50: #fffde7;
$yellow-100: #fff9c4;
$yellow-200: #fff59d;
$yellow-300: #fff176;
$yellow-400: #ffee58;
$yellow-500: #ffeb3b;
$yellow-600: #fdd835;
$yellow-700: #fbc02d;
$yellow-800: #f9a825;
$yellow-900: #f57f17;
$yellow-a100: #ffff8d;
$yellow-a200: #ff0;
$yellow-a400: #ffea00;
$yellow-a700: #ffd600;
$amber-50: #fff8e1;
$amber-100: #ffecb3;
$amber-200: #ffe082;
$amber-300: #ffd54f;
$amber-400: #ffca28;
$amber-500: #ffc107;
$amber-600: #ffb300;
$amber-700: #ffa000;
$amber-800: #ff8f00;
$amber-900: #ff6f00;
$amber-a100: #ffe57f;
$amber-a200: #ffd740;
$amber-a400: #ffc400;
$amber-a700: #ffab00;
$orange-50: #fff3e0;
$orange-100: #ffe0b2;
$orange-200: #ffcc80;
$orange-300: #ffb74d;
$orange-400: #ffa726;
$orange-500: #ff9800;
$orange-600: #fb8c00;
$orange-700: #f57c00;
$orange-800: #ef6c00;
$orange-900: #e65100;
$orange-a100: #ffd180;
$orange-a200: #ffab40;
$orange-a400: #ff9100;
$orange-a700: #ff6d00;
$deeporange-50: #fbe9e7;
$deeporange-100: #ffccbc;
$deeporange-200: #ffab91;
$deeporange-300: #ff8a65;
$deeporange-400: #ff7043;
$deeporange-500: #ff5722;
$deeporange-600: #f4511e;
$deeporange-700: #e64a19;
$deeporange-800: #d84315;
$deeporange-900: #bf360c;
$deeporange-a100: #ff9e80;
$deeporange-a200: #ff6e40;
$deeporange-a400: #ff3d00;
$deeporange-a700: #dd2c00;
$brown-50: #efebe9;
$brown-100: #d7ccc8;
$brown-200: #bcaaa4;
$brown-300: #a1887f;
$brown-400: #8d6e63;
$brown-500: #795548;
$brown-600: #6d4c41;
$brown-700: #5d4037;
$brown-800: #4e342e;
$brown-900: #3e2723;
$grey-50: #fafafa;
$grey-100: #f5f5f5;
$grey-200: #eee;
$grey-300: #e0e0e0;
$grey-400: #bdbdbd;
$grey-500: #9e9e9e;
$grey-600: #757575;
$grey-700: #616161;
$grey-800: #424242;
$grey-900: #212121;
$bluegrey-50: #eceff1;
$bluegrey-100: #cfd8dc;
$bluegrey-200: #b0bec5;
$bluegrey-300: #90a4ae;
$bluegrey-400: #78909c;
$bluegrey-500: #607d8b;
$bluegrey-600: #546e7a;
$bluegrey-700: #455a64;
$bluegrey-800: #37474f;
$bluegrey-900: #263238;
$white: #fff;
$black: #000;
