@import '../../styles/mirror-partials/common';

.status-indicator {
  display: inline-block;
  width: 11px;
  height: 11px;
  border-radius: 50%;
}

.status-indicator {
  &.pending {
    background-color: $orange-500;
  }

  &.active {
    background-color: $green-800;
  }
}

.table.members {
  th.col-activity {
    min-width: 230px;
  }

  td.col-activity {
    white-space: nowrap;
  }

  th.col-expires {
    min-width: 130px;
  }
}
