@import '../../styles/mirror-partials/common';

.login {
  width: 540px;
}

.intro {
  position: relative;
  height: 175px;
  font-size: 35px;

  .polymark {
    height: 8em;
    animation: rotateToCenter $intro-duration ease-out;
    animation-delay: $intro-delay;

    animation-fill-mode: both;
  }

  .polyname {
    height: 8em;
    margin-left: 1em;
    animation: zoom $intro-duration;
    animation-delay: $intro-delay;

    animation-fill-mode: both;
  }
}

.mirror-wrapper {
  display: inline-block;
  position: absolute;
  bottom: 25px;
  left: 8.7em;
  width: 250px;
  height: 100px;
  overflow: hidden;
  animation: addBorder 600ms ease-in;
  animation-delay: $intro-delay + $intro-duration;
  /* explicit border-left necessary for Edge and Safari to animate; no ill effects on Chrome and FF */
  border-left: 1px solid $white;
  color: $white;

  animation-fill-mode: both;

  .mirror {
    position: absolute;
    bottom: 6px;
    left: -50px;
    padding-left: 30px;
    animation: mirrorAppear $mirror-duration cubic-bezier(.230, 1, .320, 1);
    animation-delay: $intro-delay + $intro-duration + $mirror-delay;
    font-size: 75px;
    font-weight: bold;

    animation-fill-mode: both;
  }
}

// see related App.scss .app-root rules
.welcome {
  color: $white;
  font-size: calc(10px + 2vmin);
  text-align: center;

  .button {
    margin-top: 0;
    padding: .4em .8em;
    animation: fadeIn .2s ease;
    animation-delay: $intro-delay + $intro-duration + .1s;
    border: none;
    border-radius: 12px;
    background-color: $black;
    box-shadow: none;
    color: $white;
    font-size: .8em;
    cursor: pointer;

    animation-fill-mode: both;
  }
}
