@import './common';

.form-group {
  margin-bottom: 1em;
}

.form-control {
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: .375rem .75rem;
  border: 1px solid $input-border-grey;
  border-radius: .35rem;
  outline: none;
  background-color: $white;
  box-shadow: none;
  font-family: inherit;
  font-size: 1rem;
  line-height: 1.75;

  background-clip: padding-box;

  .form-text {
    color: $button-default-color;
    font-size: .8rem;
  }
}

/* styling for an inline add form */
.inline-add .form-control {
  line-height: 1;
}

.form-instruction {
  font-size: 90%;
  font-style: italic;
}

select.form-control {
  height: 2.6rem;
}

.wide.form-group {
  width: 100%;
}

textarea.form-control {
  height: 75px;
  font-family: inherit;
}

/* intentionally not classed .search to not conflict with standard .poly-icon.search */
.search-input {
  position: relative;

  input {
    padding: 3px;
    border: 1px solid $input-border-grey;
    text-indent: $input-text-indent;
  }
}

.search-input .poly-icon.search {
  position: absolute;
  top: .4em;
  right: 1px;
  line-height: 1em;

  /* Edge browsers have an 'x' in text input field, make search icon not position over it */
  @supports (-ms-ime-align: auto) {
    right: 22px;
  }
}

input::placeholder {
  text-indent: $input-text-indent;
}

.form-group.invalid {
  label {
    color: $red-800;
  }

  .form-input,
  select.form-control {
    border: 1px solid $red-800 !important;
  }

  .invalid-warning {
    margin-left: .3em;
    color: $red-900;
    font-style: italic;
  }
}

/* based on Poly Lens */
.switch-input {
  input {
    @extend %sr-only;
  }

  .form-option label {
    display: inline-block;
    position: relative;
    width: $form-input-width !important;
    height: 16px;
    margin: 0;
    padding: 0;
    transition: background .5s ease;
    border: none;
    border-radius: 8px;
    background: $grey-500;
    vertical-align: middle;

    /* circular button on switch */
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 2px;
      right: auto;
      left: 2px;
      width: 12px;
      height: 12px;
      transition: all .3s ease;
      border-radius: 50%;
      background: $white;
    }
  }

  .form-option input:checked + label {
    background: $blue-600;

    &::before {
      left: calc(#{$form-input-width - 14px});
      transition: all .3s ease;
    }
  }
}
