// @help - visualization: https://cubic-bezier.com/#.55,.55,.67,.19
$easing-gentle-ease-in: cubic-bezier(.55, .055, .675, .19);
// @help - visualization: https://cubic-bezier.com/#.215,.61,.355,1
$easing-strong-ease-out: cubic-bezier(.215, .61, .355, 1);
$spin-time: 2s;

@keyframes move-up-and-scale-down {
  0% {
    transform: scale(1.5) translateY(150px);
  }

  100% {
    transform: scale(1) translateY(0);
  }
}

@keyframes poly {
  0% {
    transform: rotate(0);
    animation-timing-function: $easing-gentle-ease-in;
  }

  50% {
    transform: rotate(360deg);
    animation-timing-function: $easing-strong-ease-out;
  }

  100% {
    transform: rotate(720deg);
  }
}

@keyframes icon-spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
