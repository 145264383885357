@import '../../styles/mirror-partials/common';

.device-left-col {
  margin-right: 3.5em;
  // 4px margin used when screen sizes gets small and content stacks
  margin-bottom: 2em;

  label {
    display: block;
    font-weight: bold;
  }
}

.device-section.red-border {
  border: 1px solid $red-100;
}
