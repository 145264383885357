@import '../node_modules/cesium/Build/Cesium/Widgets/widgets.css';

body {
  margin: 0;
  overflow-x: hidden;
  background-size: cover;
  font-family: 'Roboto', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
