@import '../../styles/mirror-partials/common';

// this file encompasses styling for Tabs.tsx and Tab.tsx
ul.tabs-nav.nav.navbar-nav {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-around;
  margin: 0 0 2em 0;
  padding: 0;
  border-radius: 8px;
  background-color: $grey-100;
  list-style: none;

  &.navbar-center {
    text-align: center;
  }

  li.tab {
    display: inline-block;
  }

  li div {
    display: block;
    padding: 7px 50px;
    color: $black;
    font-weight: 700;
    line-height: 15px;
    cursor: pointer;

    &.active {
      border-radius: 8px;
      background-color: #f1f2f3;
      box-shadow: 0 16px 16px rgba(0, 0, 0, .025), 0 8px 8px rgba(0, 0, 0, .05), 0 4px 4px rgba(0, 0, 0, .075), 0 2px 2px rgba(0, 0, 0, .1), 0 1px 1px rgba(0, 0, 0, .125);
    }
  }
}

@for $o from 1 through 6 {
  ul.tabs-nav-children-#{$o} li {
    width: calc(100% / #{$o});
  }
}
