@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slide-left {
  from {
    right: -100%;
  }

  to {
    right: 0;
  }
}

@keyframes addBorder {
  0% {
    border-left: 1px solid rgba(0, 0, 0, 0%);
  }

  100% {
    border-left: 1px solid rgba(255, 255, 255, 100%);
  }
}

@keyframes mirrorAppear {
  0% {
    left: -250px;
    opacity: 0;
    letter-spacing: 5px;
  }

  100% {
    left: 0;
    opacity: 1;
    letter-spacing: normal;
  }
}

@keyframes zoom {
  0% {
    transform: scale(10) translate(300%, 300%);
    opacity: .4;
  }

  25% {
    transform: scale(1) translate(0, -15%);
    opacity: .8;
  }

  85% {
    transform: scale(1) translate(0, -15%);
    opacity: .8;
  }

  100% {
    transform: scale(.5) translate(-120%, -30%);
    opacity: 1;
  }
}

@keyframes rotateToCenter {
  0% {
    transform: rotate(0deg) scale(5) translate(-10%, 40%);
    opacity: .4;
  }

  25% {
    transform: rotate(240deg) scale(1) translate(35%, 15%);
    opacity: .8;
  }

  85% {
    transform: rotate(240deg) scale(1) translate(35%, 15%);
    opacity: .8;
  }

  100% {
    transform: rotate(240deg) scale(.5) translate(94%, 4%);
    opacity: 1;
  }
}
