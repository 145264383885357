@import '../../styles/mirror-partials/common';

.activation {
  $gutter: 2em;
  $inlay: 1.1em;
  position: relative;
  margin-left: $inlay;
  padding-left: $gutter;
  border-left: 1px solid $grey-400;

  .poly-icon.place {
    position: absolute;
    left: -$inlay;
    padding-top: 3px;
    padding-bottom: 3px;
    background-color: $white;
  }

  .simple-earth {
    width: 90%;
    height: 220px;
    margin-top: .5em;
    border: 1px solid $grey-200;
  }

  & + .activation {
    padding-top: 1em;
  }
}
